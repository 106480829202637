import {
  SET_ACCESS_TOKEN,
  STORE_ASSIGNMENT_ID,
  SET_RETURN_TO_URL,
  SET_TIMEZONE,
  SET_PLATFORM,
  SET_RLDB_LAUNCH,
  SET_MODULE_LAUNCH,
  SET_SDK_TRANSLATIONS,
  SET_COURSE_WORKFLOW_STATE,
  SET_ITEM_BANKS_SCOPE,
  SET_CURRENT_USER,
  SET_LAUNCH_URL,
  SET_RCS_HOST,
  SET_RCS_JWT,
  SET_CANVAS_CONTEXT_ID,
  SET_CANVAS_LOCAL_CONTEXT_ID,
  SET_CANVAS_TOOL_ID,
  SET_USER_CANVAS_ID,
  SET_CONTEXT_TYPE,
  SET_CANVAS_HOST,
  SET_CANVAS_ASSIGNMENT_ID,
  SET_RESTRICT_QUANTITATIVE_DATA,
  SET_GRADING_SCHEME
} from '../constants'

export function setAccessToken (accessToken) {
  return { type: SET_ACCESS_TOKEN, access_token: accessToken }
}

export function setCurrentUser (currentUser) {
  return { type: SET_CURRENT_USER, currentUser }
}

export function setReturnToUrl (url) {
  return { type: SET_RETURN_TO_URL, url }
}

export function setItemBanksScope (itemBanksScope) {
  return { type: SET_ITEM_BANKS_SCOPE, itemBanksScope }
}

export function storeAssignmentId (assignmentId) {
  return { type: STORE_ASSIGNMENT_ID, assignmentId }
}

export function setTimeZone (timeZone) {
  return { type: SET_TIMEZONE, timeZone }
}

export function setPlatform (platform) {
  return { type: SET_PLATFORM, platform }
}

export function setRLDBLaunch (rldbLaunch) {
  return { type: SET_RLDB_LAUNCH, rldbLaunch }
}

export function setSdkTranslations (sdkTranslations) {
  return { type: SET_SDK_TRANSLATIONS, sdkTranslations }
}

export function setCourseWorkflowState (courseWorkflowState) {
  return { type: SET_COURSE_WORKFLOW_STATE, courseWorkflowState }
}

export function setModuleLaunch (moduleLaunch, canvasAssignmentId) {
  return { type: SET_MODULE_LAUNCH, moduleLaunch, canvasAssignmentId }
}

export function setLaunchUrl (launchUrl) {
  return { type: SET_LAUNCH_URL, launchUrl }
}

export function setRCSHost (rcsHost) {
  return { type: SET_RCS_HOST, rcsHost }
}

export function setRCSJwt (rcsJwt) {
  return { type: SET_RCS_JWT, rcsJwt }
}

export function setCanvasContextId (canvasContextId) {
  return { type: SET_CANVAS_CONTEXT_ID, canvasContextId }
}

export function setCanvasLocalContextId (canvasLocalContextId) {
  return { type: SET_CANVAS_LOCAL_CONTEXT_ID, canvasLocalContextId }
}

export function setCanvasToolId (canvasToolId) {
  return { type: SET_CANVAS_TOOL_ID, canvasToolId }
}

export function setUserCanvasId (userCanvasId) {
  return { type: SET_USER_CANVAS_ID, userCanvasId }
}

export function setContextType (contextType) {
  return {type: SET_CONTEXT_TYPE, contextType}
}

export function setCanvasHost (canvasHost) {
  return { type: SET_CANVAS_HOST, canvasHost }
}

export function setCanvasAssignmentId (canvasAssignmentId) {
  return { type: SET_CANVAS_ASSIGNMENT_ID, canvasAssignmentId }
}

export function setRestrictQuantitativeData (restrictQuantitativeData) {
  return { type: SET_RESTRICT_QUANTITATIVE_DATA, restrictQuantitativeData }
}

export function setGradingScheme (gradingScheme) {
  return { type: SET_GRADING_SCHEME, gradingScheme }
}
