import { CREATE_PARTICIPANT_SESSION } from '../constants'

function completedLastAttemptSignificantly(participantSessions) {
  return participantSessions.some((participantSession) => {
    const {
      display_item_response_correctness_qualifier,
      display_item_response_qualifier,
      attempts_remain
    } = participantSession.viewPermissions || {}
    const significant = display_item_response_qualifier?.includes('after_last_attempt')
      || display_item_response_correctness_qualifier?.includes('after_last_attempt')
    return !attempts_remain && significant
  })
}

function existingParticipantSessions(state, action) {
  if (completedLastAttemptSignificantly(Object.values(action.participantSessions)) && action.scope === 'quiz_session.results') {
    // need to clear out participantSessions that think there are still attempts remaining
    // to force them to be re-fetched if requested (otherwise they won't load for the user until page refresh)
    return Object.fromEntries(
      Object.entries(state[action.scope] || {}).filter(([id, participantSession]) => (
        !participantSession.viewPermissions.attempts_remain
      ))
    )
  }
  return state[action.scope]
}

export default (state = {}, action = {}) => {
  switch (action.type) {
    case CREATE_PARTICIPANT_SESSION:
      return {
        ...state,
        [action.scope]: {
          ...existingParticipantSessions(state, action),
          ...action.participantSessions
        }
      }
    default:
      return {
        ...state
      }
  }
}
