import {
  SET_LAUNCH_TOKEN
} from '../constants'

export function setLaunchToken(launchToken) {
  return {
    tokenInfo: { launchToken },
    type: SET_LAUNCH_TOKEN
  }
}
