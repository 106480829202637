import {
  ACCESS_TOKEN_STORAGE_KEY
} from './constants'

import { getTokenInfo, getFeatures } from './actions/apiActions'
import {
  setAccessToken,
  setCurrentUser,
  setReturnToUrl,
  storeAssignmentId,
  setTimeZone,
  setPlatform,
  setRLDBLaunch,
  setSdkTranslations,
  setCourseWorkflowState,
  setItemBanksScope,
  setModuleLaunch,
  setLaunchUrl,
  setRCSHost,
  setRCSJwt,
  setCanvasContextId,
  setCanvasLocalContextId,
  setCanvasToolId,
  setUserCanvasId,
  setContextType,
  setCanvasHost,
  setCanvasAssignmentId,
  setRestrictQuantitativeData,
  setGradingScheme
} from './actions/context'
import { storeOauthToken } from './actions/oauthToken'
import { setLaunchToken } from './actions/launchToken'

import config from './config'
import spoofRole from './common/lib/spoofRole'

const inDevelopment = config.nodeEnv === 'development'

// Get value specified by identifier from storage
function getFromStorage (storage, identifier) {
  return storage && storage.getItem(identifier)
}

function parseQueryString (query) {
  const pairs = query.replace(/^\?/, '').split('&')
  return pairs.reduce((params, pair) => {
    const [key, value] = pair.split('=')
    return { ...params, [key]: decodeURIComponent(value) }
  }, {})
}

function isValidUrl (url) {
  return !!url && /^http/i.test(url)
}

function safeUrl(url) {
  if ( isValidUrl(url) ) {
    return url
  }
  return ''
}

function boolStringToBool (boolString) {
  return (boolString || '').toString().toLowerCase() === 'true'
}

export function launchParams (query, sessionStorage, localStorage) {
  const hasWindowLaunchParams = window.launch_params && window.launch_params.access_token
  const params = (hasWindowLaunchParams ? window.launch_params : parseQueryString(query)) || {}
  let accessToken = params.access_token || getFromStorage(sessionStorage, ACCESS_TOKEN_STORAGE_KEY)
  const returnToUrl = safeUrl(params.return_to) ||
    safeUrl(getFromStorage(sessionStorage, 'return_to')) ||
    safeUrl(getFromStorage(localStorage, 'return_to'))
  const assignmentId = params.assignment_id || getFromStorage(sessionStorage, 'assignment_id')
  const highContrast = (params.high_contrast || getFromStorage(sessionStorage, 'high_contrast')) === 'true'
  const timeZone = params.timezone || getFromStorage(sessionStorage, 'timezone') || 'America/Denver'
  const platform = params.platform || getFromStorage(sessionStorage, 'platform')
  const rcsHost = params.rcs_host || getFromStorage(localStorage, 'rcs_host')
  const rcsJwt = params.rcs_jwt || getFromStorage(localStorage, 'rcs_jwt')
  const rldbLaunch = params.rldb_launch || getFromStorage(sessionStorage, 'rldb_launch')
  const launchUrl = params.launch_url
  const moduleLaunch = params.module_launch
  const canvasAssignmentId = params.canvas_assignment_id || getFromStorage(sessionStorage, 'canvas_assignment_id')
  const canvasContextId = params.canvas_context_id || getFromStorage(sessionStorage, 'canvas_context_id')
  const canvasLocalContextId = params.canvas_local_context_id || getFromStorage(sessionStorage, 'canvas_local_context_id')
  const canvasToolId = params.canvas_tool_id || getFromStorage(sessionStorage, 'canvas_tool_id')
  const userCanvasId = params.user_canvas_id || getFromStorage(sessionStorage, 'user_canvas_id')
  const contextType = params.context_type || getFromStorage(sessionStorage, 'context_type')
  const canvasHost = params.canvas_host || getFromStorage(localStorage, 'canvas_host')
  const restrictQuantitativeData = boolStringToBool(params.restrict_quantitative_data || getFromStorage(sessionStorage, 'restrict_quantitative_data'))
  const rawGradingScheme = params.grading_scheme || getFromStorage(sessionStorage, 'grading_scheme')
  const gradingScheme = rawGradingScheme && typeof rawGradingScheme === 'string' ? JSON.parse(rawGradingScheme) : rawGradingScheme
  const courseWorkflowState = params.course_workflow_state || getFromStorage(localStorage, 'course_workflow_state')
  const itemBanksScope = params.item_banks_scope || getFromStorage(sessionStorage, 'item_banks_scope')
  const currentUser = params.current_user || JSON.parse(getFromStorage(sessionStorage, 'current_user') || null)
  const launchToken = params.launch_token

  if (!accessToken && inDevelopment) {
    accessToken = spoofRole()
  }

  return {
    accessToken,
    assignmentId,
    userCanvasId,
    canvasContextId,
    canvasLocalContextId,
    canvasHost,
    courseWorkflowState,
    currentUser,
    highContrast,
    itemBanksScope,
    launchUrl,
    platform,
    returnToUrl,
    rldbLaunch,
    rcsHost,
    rcsJwt,
    contextType,
    moduleLaunch,
    canvasAssignmentId,
    canvasToolId,
    timeZone,
    restrictQuantitativeData,
    gradingScheme,
    launchToken
  }
}

export function initializeStore (
  store,
  {
    accessToken,
    assignmentId,
    courseWorkflowState,
    currentUser,
    isGrading = undefined,
    itemBanksScope,
    launchUrl,
    platform,
    returnToUrl,
    rldbLaunch,
    moduleLaunch,
    canvasAssignmentId,
    canvasToolId,
    sdkTranslations,
    timeZone,
    contextType,
    rcsHost,
    rcsJwt,
    userCanvasId,
    canvasContextId,
    canvasLocalContextId,
    canvasHost,
    restrictQuantitativeData,
    gradingScheme,
    launchToken
  }
) {

  store.dispatch(storeOauthToken(accessToken))
  store.dispatch(getTokenInfo({ accessToken }))
  store.dispatch(setAccessToken(accessToken))
  store.dispatch(storeAssignmentId(assignmentId))
  store.dispatch(getFeatures({ accessToken }))
  store.dispatch(setCurrentUser(currentUser))
  store.dispatch(setTimeZone(timeZone))
  store.dispatch(setPlatform(platform))
  store.dispatch(setRLDBLaunch(rldbLaunch))
  store.dispatch(setSdkTranslations(sdkTranslations))
  store.dispatch(setCourseWorkflowState(courseWorkflowState))
  store.dispatch(setItemBanksScope(itemBanksScope))
  store.dispatch(setRCSHost(rcsHost))
  store.dispatch(setRCSJwt(rcsJwt))
  store.dispatch(setContextType(contextType))
  store.dispatch(setUserCanvasId(userCanvasId))
  store.dispatch(setCanvasContextId(canvasContextId))
  store.dispatch(setCanvasLocalContextId(canvasLocalContextId))
  store.dispatch(setCanvasToolId(canvasToolId))
  store.dispatch(setCanvasHost(canvasHost))
  store.dispatch(setCanvasAssignmentId(canvasAssignmentId))
  store.dispatch(setRestrictQuantitativeData(restrictQuantitativeData))
  store.dispatch(setGradingScheme(gradingScheme))
  store.dispatch(setLaunchToken(launchToken))

  if(moduleLaunch) {
    store.dispatch(setModuleLaunch(moduleLaunch, canvasAssignmentId))
  }

  if(launchUrl) {
    const parser = new URL(launchUrl)
    if(parser.host == window.location.host) {
      window.history.replaceState({}, '', launchUrl)
    } else {
      // eslint-disable-next-line immutable/no-mutation
      window.location = launchUrl
    }
    store.dispatch(setLaunchUrl(launchUrl))
  }

  let calculatedIsGrading = isGrading
  if(calculatedIsGrading === undefined) {
    calculatedIsGrading = window.location.href.includes('/grading')
  }
  const shouldSetReturnToUrl = !calculatedIsGrading
  if (shouldSetReturnToUrl) {
    store.dispatch(setReturnToUrl(returnToUrl))
  }
}
